.App {
  text-align: center;
}

.App-header {
  width: 100%;
  background-color: var(--demeter-black);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.25rem;
  font-weight: bold;
  color: white;
  font-family: 'Ovo', serif;
  padding: 1rem 0;
}

.App-header a {
  padding: inherit .75rem inherit .75rem !important;
}

.appNav a {
  color: #DDD !important;
}

.appNav a:hover:not(.active) {
  color: #CCC !important;
}

.appNav a.active:hover {
  color: var(--demeter-green-v) !important;
}

.App-body {
  width: 100%;
  height: max-content;
  padding-bottom: 5rem;
  max-width: 1920px;
  margin-inline: auto;
}

/* FOOTER */
.App-footer {
  background-color: var(--demeter-black);
  height: fit-content;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.flexFooter {
  align-items: baseline;
  justify-content: space-between;
  padding: .75rem .5rem;
}

.App-footer p {
  color: white;
  margin-bottom: 0;
  font-size: .85rem;
  align-self: center;
}

.LVR {
  font-family: 'Spectral SC', serif;
}

.deco {
  width: 7rem;
  font-size: .9rem !important;
}

.loginText {
  color: var(--demeter-grey);
  text-align: center;
}

.flexLogoBody {
  display: flex;
  width: fit-content;
  flex-wrap: nowrap;
  /* margin-right: .75rem; */
}

.demeterLogo {
  height: 2.1rem;
  padding: .1rem;
  align-self: center;
  transition: all .15s;
  border-radius: .25rem;
  margin-right: .5rem;
}

.demeterLogo:hover {
  background-color: var(--demeter-green);
  cursor: pointer;
}

#appVersion {
  color: var(--demeter-green);
  margin-bottom: 0;
  font-size: 1rem;
  align-self: center;
  /* margin-left: .5rem; */
  margin-right: .5rem;
}

.footerText {
  align-self: center;
}

#loading {
  text-align: center;
  margin-inline: auto;
  color: var(--demeter-green);
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

#loading svg {
  margin-right: .5rem;
}

/* SPLIT VIEW */
.bleuViewBtn {
  background-color: var(--demeter-blue) !important;
}

.bleuViewBtn:hover, .bleuViewBtn.selected {
  background-color: var(--demeter-blue-v) !important;
}

.bleuViewBtn:focus {
  border-color: var(--demeter-blue-v) !important;
}

.mauveViewBtn {
  background-color: var(--demeter-mauve) !important;
}

.mauveViewBtn:hover, .mauveViewBtn.selected {
  background-color: var(--demeter-mauve-v) !important;
}

.mauveViewBtn:focus {
  border-color: var(--demeter-mauve-v) !important;
}

.kakiViewBtn {
  background-color: var(--demeter-syracuse) !important;
}

.kakiViewBtn:hover, .kakiViewBtn.selected {
  background-color: var(--demeter-syracuse-v)!important;
}

.kakiViewBtn:focus {
  border-color: var(--demeter-syracuse-v) !important;
}

/* FILTER BAR */
.filterBar {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.filterBar input,
.filterSearch input {
  width: 15rem;
}

.filterBar select,
.filterBar button {
  align-self: center;
  margin-inline: .5rem;
}

.filterSearch,
.filterDrop {
  justify-content: center;
  align-self: center;
}

/* PAGE NAVBAR */
.navbar {
  font-size: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.navbar>.nav {
  margin: auto;
}

.navbar a {
  padding: inherit .75rem inherit .75rem !important;
}

.appPage {
  width: 80%;
  margin: auto;
}

/* BTN BAR */
.btnBar {
  width: 100%;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  display: inline-flex;
}

.btnBar>button {
  min-width: 10rem;
}

.btnBar>button.centerBtn {
  margin-inline: auto;
}

.btnBar>button.leftBtn {
  margin-right: auto;
}

.btnBar>button.rightBtn {
  margin-left: auto;
}

/* RESPONSIVE */
@media only screen and (max-width: 800px) {
  .App-header {
    padding: .5rem 0;
  }

  .appNav .nav {
    flex-direction: row;
  }

  .appNav .nav-item {
    width: 50%;
  }

  .App-body {
    padding-bottom: 2rem;
  }

  .appPage {
    width: 90%;
  }

  .pageSplit {
    flex-direction: column;
  }

  .pageSplit>div {
    width: 100% !important;
  }

  .pageSplit>div:not(:last-child) {
    margin-bottom: 1rem;
  }

  .pageSplit .popupForm {
    padding: 0;
  }

  .navbar {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem
  }

  .navbar .nav-item {
    margin-bottom: .5rem;
    width: 100%;
  }

  .navbar .nav-item:last-child {
    margin-bottom: 0;
  }

  .navbar .nav-tabs {
    border: none;
  }

  .navbar .nav-link {
    border-radius: .25rem !important;
    border: 2px solid var(--demeter-green) !important;
    color: var(--demeter-green-v) !important;
    margin-bottom: 0;
    padding: .25rem 0 !important
  }

  .navbar .nav-link:not(.active):hover {
    border-color: var(--demeter-green-v) !important;
    color: var(--demeter-green-v) !important;
  }

  .navbar .nav-link.active {
    background-color: var(--demeter-green) !important;
    color: white !important;
  }

  .navbar .nav-link.active:hover {
    background-color: var(--demeter-green-v) !important;
  }

  .btnBar {
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .btnBar>button {
    width: 90%;
    margin: auto;
  }

  .btnBar>button:not(:last-child),
  .filterBar>div:not(:last-child),
  .filterDrop>select {
    margin-bottom: .5rem;
  }

  .btnBar .btn-hidden {
    display: none;
  }

  .filterBar {
    flex-direction: column;
  }

  .filterSearch,
  .filterDrop {
    display: inline-flex;
    margin: auto;
    width: 100%;
  }

  .App-footer {
    bottom: unset;
    top: 100%;
    position: sticky;
  }

  .flexFooter,
  .footerText {
    flex-direction: column;
  }

  .flexLogoBody,
  .flexFooter .deco {
    margin: auto;
  }

  .flexLogoBody,
  .footerText {
    margin-bottom: .5rem;
  }
}