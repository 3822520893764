.LoginPage {
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    width: 100%;
}

.loginImg {
    height: 100vh;
    width: auto;
}

.flexLoginBox {
    height: 100%;
    width: 100%;
    min-width: fit-content;
    margin: auto;
    padding: 10% 0;
}

.loginTitleBox {
    width: 100%;
}

.loginTitle {
    width: inherit;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Ovo', serif;
}

.loginLine {
    border: 1.5px solid #EEE;
    width: 15rem;
    border-radius: 5px;
    margin: 0 auto;
}

.loginForm {
    width: 50%;
    margin: auto;
    min-width: 20rem;
}

.LoginForm .loginField {
    display: flex;
    flex-direction: row;
}

.loginBtn {
    margin: auto;
}

@media only screen and (max-width: 800px) {
    .LoginPage {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .loginForm {
        width: 90%;
    }

    .loginImg {
        height: auto;
        width: inherit;
    }
}