.taskRowList {
    min-width: 400px;
    margin: auto;
    flex-direction: column;
}

.taskRowBox {
    width: 100%;
}

.taskRowBox span,
.taskRowBox input {
    align-self: center;
}

.taskName {
    flex-wrap: nowrap;
    text-align: left;
    /* width: 100%; */
}

.taskRowBox input,
.taskRowBox .taskResponsable {
    margin-inline: .5rem;
}

.taskMaster {
    margin-left: .5rem;
    margin-right: auto;
}

.taskRowBox input {
    width: 5rem;
}

.taskRowBox .iconCheck {
    margin-right: .25rem;
    align-self: center;
}

.taskRow,
.taskChildRow {
    width: 100%;
    justify-content: space-between;
}

.taskResponsable,
.taskMaster {
    color: var(--demeter-grey);
}

.cellShade:hover .iconBullet,
.cellShade.priority .iconBullet {
    color: var(--demeter-grey);
}

.taskChildBox .taskChildRow:not(:last-child) {
    margin-bottom: .25rem;
}

.taskChildBox .taskChildRow:first-child {
    margin-top: .25rem;
}

.taskLine {
    margin: .25rem 0 !important;
}

.subTaskList {
    flex-direction: column;
    width: 100%;
}

.subTaskBox {
    width: 100%;
}

.subTask {
    width: 100%;
    justify-content: space-between;
}

.subTask>input {
    width: 90%;
}

.subTaskList .subTaskBox:not(:first-child)>hr {
    display: none;
}

.taskInput {
    min-width: fit-content;
    margin-left: auto;
    align-self: center;
}

.taskDesc {
    width: 100%;
    color: var(--demeter-grey);
    text-align: left;
    line-height: 1.25;
}

.taskDisplayList {
    width: 80%;
    margin-inline: auto;
}

.hisTaskList .accordion-header,
.taskDisplayList .accordion-header {
    width: 100%;
    margin: 0;
    text-align: left;
}

.hisTaskList .accordion-button,
.taskDisplayList .accordion-button {
    width: 100%;
    padding: 0;
    color: var(--demeter-orange);
    font-weight: bold;
    background-color: unset;
    border: unset;
    font-size: 1.75rem !important;
    display: inline-flex;
}

.hisTaskList .accordion-button:focus,
.taskDisplayList .accordion-button:focus {
    box-shadow: none !important;
}

.hisTaskList .accordion-button>*,
.taskDisplayList .accordion-button>* {
    align-self: center;
}

.hisTaskList .accordion-button .icon,
.taskDisplayList .accordion-button .icon {
    color: var(--demeter-grey);
}

.hisTaskList .accordion-button.collapsed .icon,
.taskDisplayList .accordion-button.collapsed .icon {
    transform: rotate(-90deg);
}

.hisTaskList .accordion-collapse.collapse.show {
    margin-bottom: 1.25rem;
}

.hisDayList {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    display: inline-flex;
}

.hisDayList .hisDayBtn {
    width: 8.6rem;
    font-size: 1.15rem !important;
    margin-inline: .5rem;
}

.hisTaskList {
    width: 100%;
}

.hisTaskRow {
    width: 100%;
}

.hisTaskRow:not(.taskParent) .hisTask {
    width: 75%;
}

.hisTaskRow.taskParent .hisTask {
    width: 100%;
}

.hisTaskRow:not(.taskParent) span:last-child {
    width: 4.25rem;
    text-align: right;
    margin-left: auto;
}

.hisTaskRow.taskParent span:last-child {
    text-align: left;
}

.hisTaskList>div:first-child hr.taskLine {
    display: none;
}

.taskParent {
    font-weight: bolder;
    font-size: 1.35rem;
}

.taskParent span:first-child {
    color: var(--demeter-green);
}

.hisCat {
    color: var(--demeter-blue);
    font-size: 2rem;
    font-weight: bold;
}

.hisNav {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hisNav span {
    margin: auto .5rem;
}

/* .hisNav svg {
    cursor: pointer;
    color: var(--demeter-grey);
    margin: auto 0;
    transition: all 0.15s;
    padding: .25rem;
}

.hisNav svg:hover {
    color: var(--demeter-green);
} */


/* TASK RESPONSIVE */
@media only screen and (max-width: 800px) {
    .taskDisplayList {
        width: 100%;
    }

    .taskRowList {
        min-width: 100%;
        line-height: 1.25;
    }

    .taskRow span {
        word-break: break-word;
    }

    .taskName {
        flex-wrap: nowrap;
        text-align: left;
        width: 100%;
    }

    .taskMaster {
        margin-right: 0;
        word-break: unset !important;
        align-self: flex-start !important;
    }

    .taskChildRow,
    .taskRow {
        flex-direction: column;
    }

    .taskInputBox {
        display: inline-flex;
        justify-content: center;
        margin-top: .25rem;
    }

    .taskInput {
        margin-left: 0;
    }

    .taskDesc {
        display: none;
    }
}