.invPage {
    width: 80%;
    margin: auto;
}

.invFilterBox {
    text-align: center;
}

.invUpdate {
    text-align: center;
    width: 100%;
}

.invTable {
    width: 100%;
}

.vendorBox {
    align-items: center;
    display: flex;
}

.invUpdateHeader {
    text-align: center;
    padding: 0 .5rem;
}

.invMAJrowLarge {
    width: 44%;
    padding: 0 .25rem;
}

.invMAJrowThin {
    width: 12%;
}

.invEditBox {
    margin-left: .5rem;
}

.invCol {
    width: 38%;
    padding: 0 .5rem;
}

.invColThin {
    width: 24%;
    padding: 0 .5rem;
}

.invCol>h2,
.invColThin>h2 {
    padding: 0 .25rem;
    margin-bottom: 0;
}

.invCol>p,
.invColThin>p {
    margin-bottom: 0;
}

.flexInvQt {
    justify-content: flex-end;
    align-items: center;
}

.flexInvQt>p {
    min-width: 3rem;
}

.invPageHeader {
    padding: 0 1.5rem;
}

.vendorListHeader,
.vendorInfo {
    justify-content: flex-start;
    /* padding: 0 .5rem; */
}

.vendorListHeader>h4 {
    width: 19.5%;
    color: var(--demeter-grey);
}

.vendorListHeader>h4:first-child {
    width: 27%;
}

.vendorListHeader>h4:last-child {
    width: 50%;
}

.vendorInfo {
    width: 90%;
    margin-right: auto;
}

.vendorInfo>span {
    width: 22%;
}

.vendorInfo>span:first-child {
    width: 30%;
}

.vendorInfo>span:last-child {
    width: 48%;
}

.vendorListBox {
    justify-content: space-between;
}

@media only screen and (max-width: 800px) {

    .invPageHeader,
    .invUpdateHeader {
        padding: 0;
    }

    .invPageHeader h2 {
        font-size: 1.75rem;
        margin: 0;
        padding: 0;
    }

    .invCol,
    .invColThin {
        padding: 0;
    }

    .flexInvQt {
        flex-direction: column;
    }

    #qty_inv2 {
        min-width: 2.75rem;
    }
}

@media only screen and (max-width: 992px) {

    .vendorListHeader h4,
    .vendorInfo span {
        width: 33% !important;
        word-break: break-word;
    }

    .vendorInfo {
        width: 100%;
        margin-right: 0;
    }

    .vendorInfo+div {
        margin-inline: auto;
    }
}