.accountList {
    margin: auto;
    width: fit-content;
}

.accountName {
    padding: 5px;
    margin-right: auto;
}

.addAcc {
    height: min-content;
    margin: .5rem 1rem;
}

.accountContent {
    width: fit-content;
    margin: auto;
}

.accountNav {
    font-size: 1.75rem;
    margin-bottom: .5rem;
}

.accountNav>.nav {
    margin: auto;
}

.accountNav a {
    padding: inherit .75rem inherit .75rem !important;
}

.accountEditBox {
    padding-left: .5rem;
}

@media only screen and (max-width: 800px) {
    .addAcc {
        margin: 1rem auto;
    }

    .accountList {
        width: 100%;
    }
}