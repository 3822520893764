/* color palette */
:root {
    --demeter-black: #201b1a;
    --demeter-black-v: #111111;
    --demeter-black-btn: #272626;
    --demeter-green: #439e75;
    --demeter-green-v: #3E8E6A;
    --demeter-green-h: #e4eee9;
    --demeter-orange: #c86604;
    --demeter-orange-v: #bc5f04;
    --demeter-orange-h: #fff7ef;
    --demeter-grey: #696969;
    --demeter-purple: rgb(117, 67, 180, .15);
    --demeter-mauve: #75439e;
    --demeter-mauve-v: #6A3E8E;
    --demeter-blue: #437a9e;
    --demeter-blue-v: #3f7091;
    --demeter-syracuse: #b1536d;
    --demeter-syracuse-v: #98475e;
}

.greenText {
    color: var(--demeter-green) !important;
}

.blueText {
    color: var(--demeter-blue) !important;
}

.purpleText {
    color: var(--demeter-mauve) !important;
}

.magentaText {
    color: var(--demeter-syracuse) !important;
}

/* defaults */
body {
    margin: 0;
    padding: 0;
    font-family: 'Antic', sans-serif;
    font-size: 1.25rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: scroll;
}

button {
    font-size: 1.25rem !important;
}

select {
    border: .1rem solid #BBB;
    border-radius: .25rem;
}

option {
    color: #495057;
}

.cursor,
select,
input[type="file"] {
    cursor: pointer;
}

.cursorDef {
    cursor: default;
}

/* ui */
::selection {
    color: white;
    background: var(--demeter-green);
}

:focus {
    border-color: var(--demeter-green) !important;
    box-shadow: 0 0 0 .2rem rgba(0, 150, 69, 0.25) !important;
    border-radius: .25rem;
}

:focus-visible {
    outline: none;
}

.alert {
    font-size: 1rem !important;
    margin: 1rem auto !important;
    text-align: center;
    min-width: 20rem;
    width: fit-content;
}

.priority {
    background-color: var(--demeter-purple);
}

.hide {
    display: none !important;
}

.invisible {
    visibility: hidden;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

/* GRID-LIKE ELEMENTS */
.cellCenter {
    line-height: 1;
    align-self: center;
    text-align: center;
}

.cellLeft {
    line-height: 1;
    align-self: center;
    text-align: left;
}

.cellShade,
.cellShadeRow>div {
    padding: .25rem;
    transition: all .15s;
    border-radius: .25rem;
}

.cellShade:hover,
.cellShadeRow>div:hover {
    background-color: var(--demeter-green-h);
}

.cellShade span,
.cellShade div .cellShade label,
.cellShade input {
    align-self: center;
}

/* app variables i guess */
.pageTitle {
    text-align: center;
    width: 100%;
    margin-top: .5rem;
}

.pageSplit {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pageSplit>div {
    width: 50%;
}

/* flex */
[class*="flex"] {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: row;
}

.flexCol {
    flex-direction: column;
}

/* ui icons */
[class*="icon"] {
    color: var(--demeter-black-btn);
    padding: 5px;
    transition: all 0.15s;
    font-size: 1.75rem;
}

.iconEdit:hover {
    color: var(--demeter-green);
}

.iconTrash:hover,
.iconUndo:hover {
    color: var(--demeter-orange);
}

.iconAdd:hover+.hide,
.iconList:hover+.hide {
    display: block;
}

.iconBullet {
    color: #CCC;
    align-self: baseline;
    transform: rotate(90deg);
}

.iconCheck {
    color: var(--demeter-green);
}

.iconCheckCentro {
    color: var(--demeter-blue);
}

.iconCheckLivreur {
    color: var(--demeter-mauve);
}

.iconCheckSyracuse {
    color: var(--demeter-syracuse);
}

/* popup ui */
.popupForm {
    padding: 1rem;
}

.popupForm label {
    margin-bottom: 0;
    padding-left: .1rem;
}

.popupForm button {
    display: inline-block;
}

.popupForm p {
    white-space: pre-wrap;
    margin-bottom: 0;
}

.popupBtnBox {
    margin: auto;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.popupBtnBox>button {
    width: 10rem;
    margin: 0 1rem 0 1rem;
}

.popupTitle {
    text-align: center;
    margin-bottom: .5rem;
}

.popupHint {
    color: var(--demeter-grey);
    text-align: center;
    line-height: 1.25;
}

.popupSelectBox {
    display: inline-block;
    width: 100%;
}

.popupSelectLabel,
.popupLabel {
    margin-right: .5rem;
}

.popupSelectLabelFull,
.popupLabelFull {
    width: 100%;
}

.popupRowSplit {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.popupRowSplit>div {
    width: 48%;
}

.popupFit {
    width: fit-content;
}

.flexPopupRow {
    width: 100%;
}

.popupCheck {
    width: fit-content;
    display: inline-flex;
    margin-left: .25rem;
}

.popupCheck>input {
    margin: 0;
    position: relative;
    transform: scale(1.3);
    align-self: center;
    cursor: pointer;
}

.popupCheck>input:focus {
    box-shadow: none !important;
}

.popupImgBox {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.popupImgBox img {
    margin-top: .75rem;
    margin-inline: auto;
    border: 2px solid var(--demeter-black);
    border-radius: .25rem;
}

.inputHint {
    width: 100%;
    text-align: left;
    display: none;
    color: var(--demeter-grey);
    line-height: 1.25;
}

.popupForm input#password:focus+.inputHint {
    display: inline-block;
    margin-top: .5rem;
}

@media only screen and (max-width: 800px) {
    .modal-dialog {
        width: 95vw;
        margin-inline: auto;
    }

    .popupBtnBox>button {
        width: 8rem;
        margin-inline: .5rem;
    }
}

/* buttons with Demeter theme */
.btn {
    transition: all 0.15s;
    justify-content: center;
}

.btn-demeter {
    background-color: var(--demeter-green);
    color: white;
}

.btn-demeter:hover {
    background-color: var(--demeter-green-v);
    color: white;
}

.btn-demeter-dark,
.btn-icon-dark {
    background-color: var(--demeter-black);
    color: white;
}

.btn-demeter-dark:hover,
.btn-icon-dark:hover {
    background-color: var(--demeter-black-v);
    border-color: var(--demeter-black-v);
    color: white;
}

.btn-demeter-dark:focus,
.btn-outline-dark:focus,
.btn-icon-dark:focus,
.btn-icon-outline:focus {
    border-color: var(--demeter-black-v) !important;
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, 0.25) !important;
}

.btn-demeter-waxed {
    background-color: var(--demeter-orange);
    color: white;
}

.btn-demeter-waxed:hover {
    background-color: var(--demeter-orange-v);
    color: white;
}

.btn-demeter-waxed:focus {
    border-color: var(--demeter-orange-v) !important;
    box-shadow: 0 0 0 .2rem rgb(200, 102, 4, 0.25) !important;
}

.btn-icon-danger {
    background-color: #DC3545;
    color: white;
}

.btn-icon-danger:hover {
    background-color: #c82333;
    color: white;
}

.btn-icon-danger:focus {
    border-color: #c82333 !important;
    box-shadow: 0 0 0 .2rem rgb(200, 35, 51, 0.25) !important;
}

.btn-icon-nav {
    background-color: none;
    color: var(--demeter-grey);
}

.btn-icon-nav:not(:disabled):hover {
    background-color: none;
    color: var(--demeter-green);
}

.btn-icon-nav:focus, .btn-icon-nav:active {
    border: none !important;
    box-shadow: none !important;
}

.btn-secondary:focus {
    border-color: #5a6268 !important;
    box-shadow: 0 0 0 .2rem rgb(90, 98, 104, 0.25) !important;
}

.btn-link {
    color: var(--demeter-green);
    text-decoration: solid;
}

.btn-link:hover {
    color: var(--demeter-green-v);
}

.btn-link:focus {
    border: none !important;
    box-shadow: none !important;
}

.btn-outline-dark,
.btn-icon-outline {
    color: var(--demeter-black);
    border: 2px solid var(--demeter-black);
}

.btn-outline-dark:hover,
.btn-icon-outline:hover {
    background-color: var(--demeter-black);
    border-color: var(--demeter-black);
    color: white;
}

.btn-outline-dark:active,
.btn-icon-outline:active {
    color: white !important;
    background-color: var(--demeter-black-v) !important;
}

.btn-hidden {
    visibility: hidden;
}

.btn-group > .btn-check {
    display: none;
}

.btn-group > label {
    min-width: 7rem;
    margin-bottom: 0;
    font-size: 1.4rem;
}

.btn-group > label:focus {
    box-shadow: none !important;
}

.btn-group > label:first-of-type {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important;
}

.btn-group > label.selected {
    background-color: var(--demeter-green-v);
}

/* btn-icon */
[class*="btn-icon"] {
    display: flex !important;
    flex-direction: row;
}

[class*="btn-icon"]>span {
    align-self: center;
    padding-right: .25rem;
}

[class*="btn-icon"]>[class*="icon"] {
    margin-right: .5rem;
    align-self: center;
}

.btn-icon-dark>[class*="icon"],
.btn-icon-outline:hover>[class*="icon"],
.btn-icon-danger>[class*="icon"] {
    color: white;
}

/* NAV LINK */
.nav-link {
    color: var(--demeter-black);
    margin-bottom: 0;
}

.nav-link.active {
    color: var(--demeter-green) !important;
}

.nav-link:hover:not(.active) {
    color: var(--demeter-black-v);
}

.nav-link:focus {
    box-shadow: none !important;
}

.nav-link-tab {
    margin-top: 1px;
    margin-bottom: -1px;
}

.nav-link-tab:focus {
    box-shadow: none !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
    border-radius: .25rem .25rem 0 0;
}

.nav-link.active:focus {
    border-color: #dee2e6 #dee2e6 #fff !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* confirmation alert */
.react-confirm-alert-body {
    color: black !important;
    font-family: "Antic", sans-serif !important;
    line-height: 1.5rem !important;
}

.react-confirm-alert-button-group {
    justify-content: center !important;
    margin-top: 1rem !important;
    flex-direction: row-reverse !important;
    line-height: 2rem !important;
}

.react-confirm-alert-body>h1 {
    text-align: center !important;
}

.react-confirm-alert-overlay {
    animation: react-confirm-alert-fadeIn .2s .2s all !important;
    background: hsla(0, 0%, 0%, .85) !important;
}

div.react-confirm-alert-overlay {
    z-index: 9999;
}

.react-confirm-alert-button-group>button {
    width: 7.5rem !important;
    background-color: var(--demeter-black) !important;
}

.react-confirm-alert-button-group>button:hover {
    background-color: var(--demeter-black-v) !important;
    border-color: var(--demeter-black-v);
}

.react-confirm-alert-button-group>button:focus {
    border-color: var(--demeter-black-v) !important;
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, 0.25) !important;
}

.react-confirm-alert-button-group>button:first-child {
    background-color: var(--demeter-orange) !important;
}

.react-confirm-alert-button-group>button:first-child:hover {
    background-color: var(--demeter-orange-v) !important;
}