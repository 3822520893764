.flexNewsPreview {
    width: 80%;
    margin: auto;
    text-align: center;
    flex-direction: column;
}

.flexNewsBox {
    justify-content: center;
}

.flexNewsEdit {
    width: fit-content;
    display: block;
    /* text-align: right; */
}

.newsHeader {
    width: fit-content;
    margin-inline: auto;
}

.newsTitle {
    width: 100%;
    margin-bottom: unset;
}

.newsContent {
    text-align: left;
    max-width: 85%;
    margin-bottom: unset;
    white-space: pre-wrap;
}

.newsBtn {
    margin: auto;
    width: 10rem;
}

.newsDate {
    color: var(--demeter-grey);
    font-size: 1.25rem;
    margin-top: .25rem;
}

.newsReceiver {
    font-size: 1.5rem;
    margin-bottom: .25rem;
    margin-top: .25rem;
}

.newsLine {
    border: 1.5px solid #EEE;
    width: 50%;
    border-radius: 5px;
    margin: 1rem auto;
}

.flexNewsPreview:last-child .newsLine {
    display: none !important;
}

.newsAdd {
    width: 80%;
    margin: auto;
}

.joinTaskBtn {
    width: 15rem !important;
}

.jointTaskEdit>label {
    align-self: center;
    margin-bottom: 0;
}

.jointTaskEdit>[class*="icon"] {
    margin-left: auto;
}

.jointTaskEdit>span {
    align-self: center;
}

.jointTaskEdit>label,
.jointTaskPreview {
    color: var(--demeter-grey);
    margin-right: .5rem;
    margin-bottom: 0;
    align-self: center;
}

.newsBody {
    border-radius: .25rem;
    padding: .5rem;
    width: 100%;
}

.newsTaskBox {
    justify-content: center;
}

.newsTaskBox span {
    align-self: center;
}

.newsTaskBox input {
    width: 5rem;
}

/* NEWS RESPONSIVE */
@media only screen and (max-width: 800px) {
    .flexNewsPreview {
        width: 100%;
    }

    .newsBody .btnBar {
        margin-top: 0;
        margin-bottom: 0;
    }

    .flexNewsEdit {
        margin-inline: auto;
    }

    .flexNewsEdit.invisible {
        display: none;
    }
}