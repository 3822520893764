.recipePage {
    width: 60%;
    margin: auto;
}

.recipeContent {
    width: 80%;
    margin: auto;
    flex-direction: row-reverse;
    justify-content: space-between;
    display: inline-flex;
}

.recipeListBox {
    width: fit-content;
    margin: 0 auto;
    margin-left: 1rem;
}

.recipeList {
    width: 100%;
    flex-direction: column;
}

.recipeList>span {
    text-align: left;
    width: 100%;
    padding-left: .5rem;
}

.filterBox {
    width: min-content;
}

.filterBox>button {
    width: 10rem;
    margin-bottom: .5rem;
}

.createRecipePage,
.singleRecipePage {
    width: 80%;
    margin: auto;
    margin-bottom: 4rem;
}

.newRecipeForm {
    width: 100%;
    text-align: left;
}

.addIngQt {
    margin-right: .5rem;
}

.addIngQt>input {
    width: 7.5rem;
}

.addIngQt>* {
    align-self: center;
}

.ingListEmpty {
    width: 100%;
    text-align: center;
}

.ingListEmpty>span {
    width: 100%;
    display: inline-block;
}

.ingListHeader {
    width: 100%;
    color: var(--demeter-grey);
    justify-content: flex-start;
    padding-inline: .25rem;
}

.ingListHeader>span {
    display: inline-block;
    text-align: left;
    min-width: fit-content;
}

.ingListRow {
    text-align: left;
    justify-content: flex-start;
}

.ingListRow>span {
    text-align: left !important;
}

.ingListCol {
    width: 50%;
}

.ingListColS {
    width: 20%;
}

.singleRecipeTitle {
    width: fit-content;
    margin: auto;
    justify-content: center;
}

.editIngListRowBox {
    width: 90%;
}

.editIngListCol {
    width: 56%;
}

.editIngListColS {
    width: 22%;
}

.IngList .editIngListRowBox {
    width: 100%;
    margin-bottom: .5rem;
}

.IngList .ingListCol,
.IngList .editIngListCol {
    width: 60%;
}

.IngList .editIngListColS {
    width: 20%;
}

.singleRecipeBack {
    width: 10rem;
}

.singleRecipeContent {
    justify-content: space-between;
}

.singleRecipeContent>.IngList {
    width: 60%
}

.singleRecipeContent>.singleRecipeInterface {
    width: 35%;
}

.calculatorBox {
    text-align: left;
}

.calculatorBox>span {
    width: 100%;
}

.calculatorBox input {
    width: 6rem;
    height: 2rem;
}

.calculatorBox label {
    margin-bottom: 0;
    align-self: center;
}

.singleRecipeBtnBox {
    width: 100%;
    flex-direction: column;
}

.singleRecipeBtnBox>button {
    width: 15rem;
    margin-bottom: .75rem;
}

@media only screen and (max-width: 800px) {
    .recipeContent {
        width: 100%;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .filterBox {
        margin-inline: auto;
        margin-bottom: 2rem;
    }

    .filterBox button {
        min-width: 15rem;
    }

    .recipeListBox {
        margin: 0;
        width: 100%;
    }

    .ingListCol,
    .editIngListCol {
        width: 50% !important;
    }

    .ingListColS,
    .editIngListColS {
        width: 25% !important;
    }

    .ingListRow svg {
        margin-inline: auto;
        margin-top: .25rem;
    }
}